import React from 'react';

import Layout from 'src/components/layout/layout';
import { PrivateRoute } from 'src/components/private-route/private-route';
import SEO from 'src/components/seo';
import SuspensionPlayer from 'src/components/suspension-player/suspension-player';

const SuspensionPlayerPage: React.FC = () => {
  return (
    <Layout>
      <SEO title="Suspension player" />
      <PrivateRoute>
        <SuspensionPlayer />
      </PrivateRoute>
    </Layout>
  );
};

export default SuspensionPlayerPage;
